import React, {Component} from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import GetJsonFormPHP from "./datatables/api/GetJsonFormPHP"
import UserProfile from './user/UserProfile'
import Translation from './user/Translation'

import Header1 from './components/Header.js'
import Login from './components/Login.js'

import Home from './components/Home.js'
//import Feed from './components/Feed.js'
import Feeds from './components/Feeds.js'
import Profile from './components/Profile.js'
import RobotEdit from './components/RobotEdit.js'
import RobotStats from './components/RobotStats.js'


class App extends Component{
  constructor() {
    super()
    this.state={
      data: [],
    }
  }
  componentDidMount() {
    if (!localStorage.getItem('language_id')) {
      if (Translation.getLanguage() == null) {
        GetJsonFormPHP('/users/translations.php?lan=de').then((result) => {
          Translation.set("en", result.data)
        })
      }
    }
    if (window.sessionStorage.getItem("page_id") !== null) {
      //console.log(`online`, new Date(), window.sessionStorage.getItem("page_id"))
      this.setOnlineState()
    }
  }
  setOnlineState() {
    GetJsonFormPHP("/users/online.php").then((result) => { })
    setTimeout(() => {
      //console.log(`online`, new Date(), window.sessionStorage.getItem("page_id"))
      this.setOnlineState()
    }, 120000)

  }

  render() {
    if (UserProfile.getId()) {
      //{parseInt(window.sessionStorage.getItem("num_robots")) < 2 ? <Route exact path="/" element={<RobotEdit />} /> : <Route exact path="/" element={<Home />} />}
      if (UserProfile.getUserLevel() <= 4) {
        if (window.sessionStorage.getItem("support") === "true") {
          //console.log("support main", window.sessionStorage.getItem("support"))
          //window.sessionStorage.setItem("support", false)
          return (
            <BrowserRouter>
              <Header1 />
              <div className="body">
                <Routes>
                  <Route exact path="/*" element={<Profile />} />
                  <Route path="/home/*" element={<Profile />} />
                  <Route path="/profile/*" element={<Profile />} />
                  <Route path="/login" element={<Login />} />
                </Routes><br />
              </div>
            </BrowserRouter>
          )
        }
        else {
          //console.log("support customer", window.sessionStorage.getItem("support"))
          return (
            <BrowserRouter>
              <Header1 />
              <div className="body">
                <Routes>
                  <Route exact path="/*" element={<Home />} />
                  <Route path="/home/*" element={<Home />} />
                  <Route path="/recipe/*" element={<Feeds />} />
                  <Route path="/profile/*" element={<Profile />} />
                  <Route path="/robot/*" element={<RobotEdit />} />
                  <Route path="/stats/*" element={<RobotStats />} />
                  <Route path="/login" element={<Login />} />
                </Routes><br />
              </div>
            </BrowserRouter>
          )
        }
      }
      else {
        //console.log("customer user", window.sessionStorage.getItem("support"))
        //console.log("customer user", window.sessionStorage)
        return (
          <BrowserRouter>
            <Header1 />
            <div className="body">
              <Routes>
                <Route exact path="/*" element={<Home />} />
                <Route path="/home/*" element={<Home />} />
                <Route path="/recipe/*" element={<Feeds />} />
                <Route path="/profile/*" element={<Profile />} />
                <Route path="/robot/*" element={<RobotEdit />} />
                <Route path="/stats/*" element={<RobotStats />} />
                <Route path="/Login" element={<Login />} />
              </Routes><br />
            </div>
          </BrowserRouter>
        )
      }
    }
    else {
      //console.log("user :", window.sessionStorage.getItem("user_id"), UserProfile.getId())
      return (
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Login />} />
          </Routes><br />
        </BrowserRouter>
      )
    }
  }
}

export default App