import React, { Component } from 'react';
//import { Route, Routes, Link } from 'react-router-dom';
import GetJsonFormPHP from "../datatables/api/GetJsonFormPHP.js";
import UserProfile from '../user/UserProfile.js'

import Translation from '../user/Translation';
//import UserList from '../datatables/UserList.js';
//import Customers from '../datatables/CustomerList.js';
import imgSupport from '../datatables/img/support.png'
import imgRemote from '../datatables/img/pc-visit.png'
import imgBug from '../datatables/img/bug_gold.png'

function t(field) { return Translation.get(field) }

class Customer extends Component {
  constructor() {
    super();
    this.state={
      geturl: "/users/get_customer_data.php",
      setCustomerUrl: "/users/set_customer_id.php",
      customer: null,
      robot: null,
      customerId: "0",
      ID: "0",
    }
  }
  componentDidMount(customerID = this.state.customerId) {
    //if (UserProfile.getServiceReportID())
    if (customerID !== "0") {
      this.setState({ ID: customerID, })
      //console.log('fetch data')
      GetJsonFormPHP(this.state.geturl + "?customer_id=" + customerID).then((result) => {
        this.setState({
          customer: result.customer,
          robot: result.robot,
        })
      })
    }
  }
  static getDerivedStateFromProps(props, state) {
    //console.log(props)
    if (state.customerId !== props.id) {
      return {
        customerId: props.id,
      }
    }
    return null
  }
  showServiceProtocol(robotID, id) {
    window.sessionStorage.setItem("service_robot_id", robotID)
    window.sessionStorage.setItem("service_report_id", id)
    this.props.loadServiceProtocol();
  }
  showErrorProtocol() {
    this.props.loadErrorProtocol();
  }
  setCustomer(id) {
    GetJsonFormPHP(this.state.setCustomerUrl + '?id=' + id).then((result) => {
      if (result.succeed) {
        UserProfile.setProfile(result, this.state.login)
        window.location.reload(false)
      }
    })
  }
  getRobotColor(id) { // ist aus TableFunctions.js
    const backColors = ['white', '#C0D4EE', '#D0E4FE', '#FFEAF5', '#FFD5E5', '#9FDCDF', '#FFCCCC', 'SeaShell', '#F5E5E0', '#E5CECC', '#F8C5D5', 'LightYellow', '#F5EECC', 'Snow', 'Snow']
    return backColors[id]
  }

  render() {
    const customer = this.state.customer
    const robot = this.state.robot
    const suRight = UserProfile.checkRight("superuser")
    const adminRight = UserProfile.checkRight("admin") || suRight
    const supportRight = UserProfile.checkRight("support")
    const customerChange = value => { this.setCustomer(customer.id) }
    //if ((customer && this.state.customerId !== customer.id) || (this.state.robotId && !customer)) this.componentDidMount()
    if (this.state.customerId !== this.state.ID) this.componentDidMount(this.state.customerId)
    return (
      <div className="body">
        {customer !== null ?
        <div className ="home_dashboard_main">
          <div className="home_dashboard">
            <table width="100%" cellSpacing="0"  cellPadding="0" border="0" className="customerhead"><tbody className="smaller">
              <tr>
                <td><b>{customer.company_name}</b></td>
                <td align="right">
                  {UserProfile.getUserLevel() <= 4 && (customer.is_trader === 0 || UserProfile.getUserLevel() <= 2) && customer.id !== 1 ? <img src={imgSupport} alt="" title={t("customer_change")} onClick={customerChange} className="pcvisit tiny" /> : null} &nbsp;
                  {supportRight ? <span>{customer.remote_id && customer.remote_id.length === 36 ? <span><a href={"https://mobile.pcvisit.de/computer/" + customer.remote_id} target="_blank" rel="noreferrer"><img src={imgRemote} alt="" title={t("customer_remote")} className="pcvisit tiny"/></a> &nbsp;</span> : null}</span>: null}
                  {adminRight && customer.is_trader === 0 ? <span>
                    <a href={"https://connect.hetwin.at/portalapitest/import/robot_imports.php?customer=" + customer.id} rel="noreferrer" target="_blank"><img src={imgBug} alt="" title="Datenlogs" className="pcvisit tiny" /></a>
                  </span> : null}
                </td>
              </tr>
            </tbody></table>
            <table width="100%" cellSpacing="1"  cellPadding="0" border="0" className="customerinfo"><tbody>
              <tr><th valign="top">{t('address')}</th><td><span dangerouslySetInnerHTML={{__html: customer.company_address.replaceAll("\n", "<br/>")}}></span></td></tr>
              <tr><th valign="top">{t('contact')}</th><td><span dangerouslySetInnerHTML={{__html: customer.contact.replaceAll("\n", "<br/>")}}></span></td></tr>
              <tr><th valign="top">{t('email')}</th><td>{customer.email}</td></tr>
              <tr><th valign="top">{t('telephone')}</th><td>{customer.phone}</td></tr>
              <tr><th valign="top">{t('trader')}</th><td>{customer.trader}</td></tr>
              <tr><th valign="top">{t('customer_info')}</th><td>{customer.info}</td></tr>
              <tr><th valign="top">{t('robot_active')}</th><td>{customer.enabled === 1 ? t("enabled") : t("disabled") }</td></tr>
            </tbody></table>
          </div>
        </div>
        : null}
        {robot !== null ? robot.map(((t1,k) => { return <div className ="home_dashboard_main"><div className="home_dashboard">
          <table width="100%" cellSpacing="0"  cellPadding="0" border="0" className="customerhead"><tbody>
            <tr><td><div style={{backgroundColor: this.getRobotColor(t1.robottype_id)}}><b>{t1.name}</b></div></td></tr>
          </tbody></table>
          <table width="100%" cellSpacing="0"  cellPadding="0" border="0" className="customerinfo"><tbody>
              <tr><th valign="top">{t('robot_serial_number')}</th><td>{t1.serial_number}</td></tr>
              <tr><th valign="top">{t('robots_ibn_date')}</th><td>{t1.deliver_date}</td></tr>
              {t1.ip ? <tr><th valign="top">{t('ip')}</th><td>{t1.ip}</td></tr> : null }
              {t1.firmware_version ? <tr><th valign="top">{t('robot_firmware_version')}</th><td>{t1.firmware_version}</td></tr> : null }
              {t1.info ? <tr><th valign="top">{t('info')}</th><td>{t1.info}</td></tr> : null }
              <tr><th valign="top">{t('robot_status')}</th><td>{t1.active >= 1 ? "in Betrieb" : "*in Fertigung" }{t1.active === 2 ? " mit Connect" : ""}</td></tr>
              {t1.services !== null ? <tr><th valign="top">{t('serviceprotocol')}</th><td>{t1.services.map(((t2, k2) => { return <span key={"service_" + t2.id} className="link" onClick={() => { this.showServiceProtocol(t2.robot_id, t2.id) } }>{t2.servicedate + " " + t2.user} <br /></span>}))}</td></tr> : null }
              {t1.num_errors ? <tr><th valign="top">{t('errors_status_error')}</th><td><span onClick={() => { this.showErrorProtocol() } } className="link">{t1.num_errors}</span></td></tr> : null }
              {t1.param ?
              <tr>
                <th valign="top">{t('robot_parameter')}</th>
                <td>
                  {t1.param.accu_count ? <div>*Accus: {t1.param.accu_count /* Aranom */}
                    {t1.param.accu_typ ? " " + t1.param.accu_typ : null }
                    {t1.param.accu_heat ? " *mit Heizung" : null }
                    {t1.param.accus ? <div className="accus"><span dangerouslySetInnerHTML={{__html: "- " + t1.param.accus.replaceAll("\n", "<br/>- ")}}></span></div> : null }
                  </div> : null }
                  {t1.param.allrad ? <div>*Allrad</div> : null }
                  {t1.param.motor ? <div>*Motor: {t1.param.motor }</div> : null }
                  {t1.param.gyro ? <div>*Gyroskop</div> : null }

                  {t1.param.model ? <div>*Model: {t1.param.model}</div> : null /* Avenger */ }
                  {t1.param.roh ? <div>*Rohgerät im Haus</div> : null }
                  {t1.param.firma ? <div>{t1.param.firma + " " + t1.param.serie}</div> : null }

                  {t1.param.mixer ? <div>
                    {t1.param.mixer.extras ? <div>{t1.param.mixer.extras}</div> : null /* Athos */ }
                    {t1.param.mixer.vol ? <div>{t1.param.mixer.vol}</div> : null /* Athos */ }
                    {t1.param.mixer.power ? <div>{t1.param.mixer.power}</div> : null /* Athos */ }
                  </div> : null }
                  {t1.param.data ? <div>*Datenschreiber</div> : null }
                </td>
              </tr> : null}
            </tbody></table>
        </div></div>  })) : null}
      </div>
    )
  }
}

export default Customer;