import TableFunctions from './api/TableFunctions.js';
import ReactTable from "react-table-6";
import Draggable from "react-draggable";
import GetJsonFormPHP, {PostJsonFormPHP} from "./api/GetJsonFormPHP";
import UserProfile from '../user/UserProfile';
import Translation from '../user/Translation';
import AlertDialog from "../user/Dialog.js";
import getStatusVars, {getStatusColors, setTableFontSize} from './api/imports.js';
import Info from "./api/Info";
import ManageCols from "./api/ManageFields";
import imgTrue from './img/true.gif';
import imgAttention from './img/attention.png';
import imgArrow from './img/arrow_right.png';
import './styles/react-table.css';
import imgOk from './img/ok.png';
import imgFalse from './img/false2.png';
import imgDetail from './img/editdetail.png';
import imgWarnOnline from './img/careful.png'

function t(field) { return Translation.get(field) }

class Edit extends TableFunctions {
  constructor() {
    super();
    this.state = {
      geturl: "/feed/get_robotfeed.php",
      //saveurl: "/robots/set_drives.php?robot_id=" + parseInt(window.sessionStorage.getItem("robot_id")),
      saveurl: "/feed/set_robotfeed.php",
      id: null,
      index: null,
      row: null,
      data: null,
      position: [], selected: null,
      empty: null,
      edit: false,
      confirmVisible: false, title: null, message: null, choise: true,
    }
    this.handleSubmit1 = this.handleSubmit1.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  reload() {
    //this.props.reload()
    GetJsonFormPHP(this.state.geturl + "&id=" + this.state.id).then((result) => {
      if (result.data === null) result.data = [this.state.empty]
      this.setState({
        data: result.data,
        selected: null,
      })
    })
  }
  handleInputChange1(value, number = null) {
    const target = value.target.name
    const re = /^[0-9\b]+$/;
    const re2 = /^[0-9.\b-]+$/;
    let ok = true;
    if (number) {
      if (number === 1 && !(value.target.value === '' || re.test(value.target.value))) ok = false
      if (number === 2 && !(value.target.value === '' || re2.test(value.target.value))) ok = false
    }
    if (ok) {
      let row = this.state.row
      for (let key in row) { if (key === target) row[key] = value.target.value }
      this.setState({
        row: row
      })
    }
  }
  handleSubmit1() {
    let row=this.state.row
    for (let key in row) {
      if (key.substring(0,1) === "_") delete row[key]
    }
    row.action = "UPDATE"
    PostJsonFormPHP(this.state.saveurl, row).then((result) => {
      this.setState({
        id: 0,
        row: null,
      })
      this.reload()
      this.handleClose()
    })
  }
  handleClose() {
    this.props.closeDiv();
  }
  static getDerivedStateFromProps(props, state) {
    return {
      row: props.row,
      position: props.position,
    }
  }
  componentDidMount() {
    //console.log(this.state.position)
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    let e1 = document.getElementById("edit_window")
    e1.style.left = (this.state.position[0]-28*em)+'px';
    e1.style.top =(this.state.position[1]-2*em)+'px';
  }

  render() {
    //console.log(this.state.data)
    //const supportRight = UserProfile.checkRight("support")
    const userRight = UserProfile.checkRight("custuser")
    if (this.state.id !== 0) {
      let e1 = document.getElementById("edit_window")
      if (e1){
        const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
        e1.style.left = (this.state.position[0]-28*em)+'px';
        e1.style.top = (this.state.position[1]-2*em)+'px';
      }
      return (
        <Draggable handle=".handle">
        <div className="edittable" id="edit_window">
          <div className="header">
            <div className="header_inline handle">{t('robotfeed_forage_container') + " " + this.state.row.name}</div>
            <div className="header_inline close"><img src={require("./img/close.png")} alt={t('close')} className="close" onClick={this.handleClose} onMouseOver={(e) => {e.currentTarget.src = require("./img/close_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("./img/close.png")}} /></div>
          </div>
          <form>
          {userRight ? <div>
            <table width="100%">
            <thead>
              <tr><th colSpan="2">{t('robotfeed_forage_start')}</th><th>{t('robotfeed_forage_end')}</th></tr>
            </thead>
            <tbody>
              <tr>
                <td>{t('backward')}:<br/><input type="text" name="forage_start_back" value={this.state.row.forage_start_back} onChange={(value) => { this.handleInputChange1(value, 2)}} className="input_number_4" /> sec</td>
                <td>{t('forward')}:<br/><input type="text" name="forage_start_forward" value={this.state.row.forage_start_forward} onChange={(value) => { this.handleInputChange1(value, 2)}} className="input_number_4" /> sec</td>
                <td>{t('backward')}:<br/><input type="text" name="forage_end_back" value={this.state.row.forage_end_back} onChange={(value) => { this.handleInputChange1(value, 2)}} className="input_number_4" /> sec</td>
              </tr>
            </tbody>
            </table>
            <table>
            <thead>
              <tr><th colSpan="4">{t('robotfeed_fillingkind')}</th></tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="2">{t('robotfeed_forage_delay')}:</td>
                <td colSpan="2"><input type="text" name="forage_delay_auger" value={this.state.row.forage_delay_auger} onChange={(value) => { this.handleInputChange1(value, 2)}} className="input_number_4" /> sec</td>
              </tr>
              <tr>
                <td className="nowarp">{t('robotfeed_forage_fast')}:</td>
                <td className="nowarp">{t('robotfeed_forage_on')}:<br/><input type="text" name="forage_fast_on" value={this.state.row.forage_fast_on} onChange={(value) => { this.handleInputChange1(value, 2)}} className="input_number_4" /> sec</td>
                <td className="nowarp">{t('robotfeed_forage_off')}:<br/><input type="text" name="forage_fast_off" value={this.state.row.forage_fast_off} onChange={(value) => { this.handleInputChange1(value, 2)}} className="input_number_4" /> sec</td>
                <td className="nowarp">{t('robotfeed_forage_speed')}:<br/><input type="text" name="forage_fast_speed" value={this.state.row.forage_fast_speed} onChange={(value) => { this.handleInputChange1(value, 2)}} className="input_number_4" /> %</td>
              </tr>
              <tr>
                <td className="nowarp" colSpan="2">{t('robotfeed_forage_border')}:</td>
                <td colSpan="2"><input type="text" name="forage_weight" value={this.state.row.forage_weight} onChange={(value) => { this.handleInputChange1(value, 2)}} className="input_number_4" /> kg</td>
              </tr>
              <tr>
                <td className="nowarp">{t('robotfeed_forage_slow')}:</td>
                <td className="nowarp">{t('robotfeed_forage_on')}:<br/><input type="text" name="forage_slow_on" value={this.state.row.forage_slow_on} onChange={(value) => { this.handleInputChange1(value, 2)}} className="input_number_4" /> sec</td>
                <td className="nowarp">{t('robotfeed_forage_off')}:<br/><input type="text" name="forage_slow_off" value={this.state.row.forage_slow_off} onChange={(value) => { this.handleInputChange1(value, 2)}} className="input_number_4" /> sec</td>
                <td className="nowarp">{t('robotfeed_forage_speed')}:<br/><input type="text" name="forage_slow_speed" value={this.state.row.forage_slow_speed} onChange={(value) => { this.handleInputChange1(value, 2)}} className="input_number_4" /> %</td>
              </tr>
              <tr><td colSpan="4" align="center"><input type="button" value={t("save")} onClick={this.handleSubmit1} className="button"/></td></tr>
            </tbody>
          </table>
          </div> : null }
          </form>
          <AlertDialog  openDialog = {this.state.confirmVisible} title = {this.state.title} message = {this.state.message} choise = {this.state.choise} callback = {this.handleDelete}/>
        </div>
        </Draggable>
      )
    }
    else return null
  }
}


class RobotFeed extends TableFunctions {
  constructor() {
    super()
    this.state={
      geturl: "/feed/get_robotfeed.php",
      saveurl: "/feed/set_robotfeed.php",
      updateurl: "/feed/send_robotfeed.php",
      checkurl: "/feed/check_robotfeed.php",
      setTableUrl: "/users/change_tablesize.php",
      data: [], empty: null, selected: null,  // Table data
      customers: null, density: null, fillingkind: null, feeder: null, feed: null, units: ['kg', 'g'], position_side: [t('feed_right'), t('feed_left'), t('feed_middle'),], mixAtFill: [t('no'), t('yes')], // Select input options
      confirmVisible: false, title: null, message: null, choise: true,  // OK popup and Error Messages
      status: getStatusVars(), statusColor: getStatusColors(), fontSize: setTableFontSize(),  // Status and colors
      showHelp: false, showHelpId: null, // Info popup
      showPosition: [], showHideEdit: false,
      showfields: null, manageFields: false, // Show table fields
      historic: false,
      robotOnline: 0,
    }
    this.closeDiv = this.closeDiv.bind(this)
  }
  componentDidMount(historic = this.state.historic) {
    let addLink = ""
    if (historic) addLink = "?historic=1"
    GetJsonFormPHP(this.state.geturl+addLink).then((result) => {
      this.setState({ empty: result.empty, })
      if (result.data === null) result.data = [this.state.empty]
      this.setState({
        data: result.data,
        customers: result.customers,
        density: result.density,
        fillingkind: result.fillingkind,
        feed: result.feed,
        feeder: result.feeder,
        showfields: result.showfields,
        robotOnline: result.robot_online,
      })
    })
  }
  reload(historic) {
    this.componentDidMount(historic)
  }
  showHistoric() {
    this.setState({ historic: !this.state.historic, })
    this.componentDidMount(!this.state.historic)
  }
  checkUsage() {
    GetJsonFormPHP(this.state.checkurl).then((result) => {
      this.componentDidMount(this.state.historic);
    })
  }
  closeDiv() {
    this.setState({
      selected: null,
      showHideEdit: false,
    })
    this.componentDidMount()
  }
  createColumns() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*this.state.fontSize
    const supportRight = UserProfile.checkRight("support")
    const userRight = UserProfile.checkRight("custuser")
    let showFeeder = false
    if (this.state.feeder !== null && Object.entries(this.state.feeder).length > 1) showFeeder = false
    const w = this.state.fieldWidths
    const fontSize = this.state.fontSize
    const columns = [
      { accessor: 'id', show: false, },
      { accessor: 'org_id', show: false,},
      { accessor: 'used', show: false,},
      this.addSelectCell("customer", "customer_id", this.state.customers, (w && w['customer_id'] ? w['customer_id'] : 6)*fontSize, false),
      this.addSelectCell("robot_name", "robot_id", this.state.feeder, (w && w['robot_id'] ? w['robot_id'] : 8)*fontSize, showFeeder),
      this.addTextCell('feed_sps_nr', 'sps_nr', 1, (w && w['sps_nr'] ? w['sps_nr'] : 3)*fontSize, true, '', null, null, null, false),
      //this.addTextCell('name', 'name'),
      this.addSelectCell("feed_name", "feed_id", this.state.feed, (w && w['feed_id'] ? w['feed_id'] : 0)*fontSize), // ,15
      this.addTextCell('robotfeed_sequence', 'sequence', 10, (w && w['sequence'] ? w['sequence'] : 4)*fontSize, this.state.showfields ? this.state.showfields['sequence'] : true),
      this.addTextCell('robotfeed_mix', 'mix', 1, (w && w['mix'] ? w['mix'] : 4)*fontSize, this.state.showfields && typeof this.state.showfields['mix'] !== 'undefined' ? this.state.showfields['mix'] : false, "%", null, this.getColor('readOnly'), null, false),
      //this.addSelectCell("robotfeed_feeddensity_id", "feeddensity_id", this.state.density, 6*this.state.fontSize, this.state.showfields ? this.state.showfields['feeddensity_id'] : true, null, null, true, false),
      this.addTextCell('robotfeed_position', 'position', 2, (w && w['position'] ? w['position'] : 4)*fontSize, this.state.showfields ? this.state.showfields['position'] : true, "m", null, this.getColor('readOnly'), null, false),
      //this.addSelectCell("robotfeed_position_side", "position_side", this.state.position_side, 6*this.state.fontSize, this.state.showfields ? this.state.showfields['position_side'] : true, null, null, false, false),
      //this.addTextCell('feed_price', 'price', 2, (w && w['animals'] ? w['animals'] : 4)*fontSize, this.state.showfields ? this.state.showfields['price'] : true, "€"),
      this.addSelectCell("robotfeed_fillingkind_id", "fillingkind_id", this.state.fillingkind, (w && w['fillingkind_id'] ? w['fillingkind_id'] : 6)*fontSize, this.state.showfields ? this.state.showfields['fillingkind_id'] : true, null, this.getColor('readOnly'), false, false),
      this.addTextCell('robotfeed_dm', 'dm', 1, (w && w['dm'] ? w['dm'] : 6.5)*fontSize, false, "%"),
      this.addSelectCell("feed_unit", "unit", this.state.units, (w && w['unit'] ? w['unit'] : 4)*fontSize, true, "center"),
      //this.addTextCell('robotfeed_wait_period', 'wait_period', 1, 5*this.state.fontSize, this.state.showfields ? this.state.showfields['wait_period'] : true, "", null, this.getColor('notused'), null, false),
      //this.addTextCell('robotfeed_mix_at_fill', 'mix_at_fill', 1, 5*this.state.fontSize, this.state.showfields ? this.state.showfields['mix_at_fill'] : true),
      //this.addSelectCell('robotfeed_mix_at_fill', 'mix_at_fill', 1, 5*this.state.fontSize, this.state.showfields ? this.state.showfields['mix_at_fill'] : true),
      this.addSelectCell("robotfeed_mix_at_fill", "mix_at_fill", this.state.mixAtFill, (w && w['mix_at_fill'] ? w['mix_at_fill'] : 4)*fontSize, this.state.showfields ? this.state.showfields['mix_at_fill'] : true, "center", null, false, true,false, false, null, true),
      //this.addTextCell('robotfeed_mix_after', 'mix_after', 1, 5*this.state.fontSize, this.state.showfields ? this.state.showfields['mix_after'] : true),
      this.addSelectCell("robotfeed_mix_go", "mix_go", this.state.mixAtFill, (w && w['mix_go'] ? w['mix_go'] : 4)*fontSize, this.state.showfields ? this.state.showfields['mix_go'] : true, "center", null, false, true, false, false, null, true),
      //this.addTextCell('robotfeed_mix_go', 'mix_go', 1, 5*this.state.fontSize, this.state.showfields ? this.state.showfields['mix_go'] : true),
      this.addSelectCell("robotfeed_to_mix_pos", "to_mix_pos", this.state.mixAtFill,(w && w['to_mix_pos'] ? w['to_mix_pos'] : 4)*fontSize, this.state.showfields ? this.state.showfields['to_mix_pos'] : true, "center", null, false, true, false, false, null, true),
      //this.addTextCell('robotfeed_to_mix_pos', 'to_mix_pos', 1, 5*this.state.fontSize, this.state.showfields ? this.state.showfields['to_mix_pos'] : true),
      //this.addTextCell('robotfeed_mix_speed', 'mix_speed', 2, 5*this.state.fontSize, this.state.showfields ? this.state.showfields['mix_speed'] : true, "%", null, null, null, true, false,),
      this.addTextCell('robotfeed_mix_speed', 'mix_speed', 2, (w && w['mix_speed'] ? w['mix_speed'] : 5)*fontSize, this.state.showfields ? this.state.showfields['mix_speed'] : true, "%", null, null, null, true, false,),
      this.addNumberCell('robotfeed_weigh_start', 'weigh_start', 2, -999, 999, (w && w['weigh_start'] ? w['weigh_start'] : 5)*fontSize, this.state.showfields ? this.state.showfields['weigh_start'] : true, "kg", null, null, null, true, false, 1),
      this.addNumberCell('robotfeed_fill_offset', 'fill_offset', 2, -99, 99, (w && w['fill_offset'] ? w['fill_offset'] : 5)*fontSize, this.state.showfields ? this.state.showfields['fill_offset'] : true, "kg", null, null, null, true, false, 2),
      { accessor: 'check',
        Header: t('recipe_check'),
        Cell: ({value: initialValue, column: { id }, row,}) => {
          //console.log(row.name, row.check, row.fillingkind_id)
          if (row.check <= 0) {
            let nofill = "", prefix = "";
            if (row.check === -3) prefix = prefix + t("error_duplicate_order")
            if (row.fillingkind_id < 1) nofill = <img src={imgAttention} alt={t("error_no_fillingkind")} title={t("error_no_fillingkind")} />
            if (row.status === 3) return (<div width="100%" align="center">{prefix.length > 0 ? <img src={imgAttention} alt={prefix} title={prefix} /> : null}{nofill}<img src={imgArrow} alt={t("-->")} title={t("transfer_to_robot")} /></div>)
            else if (row.status === 4) return (<div width="100%" align="center">{prefix.length > 0 ? <img src={imgAttention} alt={prefix} title={prefix} /> : null}{nofill}<img src={imgTrue} alt={t("status_active")} title={t("status_active")} /></div>)
            else if (row.status > 4) return (<div width="100%" align="center">{prefix.length > 0 ? <img src={imgAttention} alt={prefix} title={prefix} /> : null}{nofill}<img src={imgFalse} alt={t("disabled")} title={t("disabled")} /></div>)
            else return (<div width="100%" align="center">{prefix.length > 0 ? <img src={imgAttention} alt={prefix} title={prefix} /> : null}{nofill}<img src={imgOk} alt={t("ok")} title={t("ok")} /></div>)
          }
          else {
            let title = ""
            if (row.check === 1) title = title + t("error_duplicate_sps_nr")
            if (row.check === 2) title = title + t("error_invalid_spsnr")
            return (<div width="100%" align="center"><img src={imgAttention} alt={title} title={title} /></div>)
          }
        },
        getProps: (state, rowInfo) => ({ style: { textAlign: "center", backgroundColor: rowInfo.row.status === 5 || rowInfo.row.status === 6 ? this.getStatusBackColor(rowInfo.row.status) : this.getColor('readOnly') } }),
        width:  (w && w['check'] ? w['check'] : 4.5)*fontSize*em,
      },
      this.addSelectCell("status", "status", this.state.status, (w && w['status'] ? w['status'] : 5.3)*fontSize, supportRight, 'center', this.getColor('cellAdmin')),
      this.addDateCell('create_time', 'last_update', false, (w && w['last_update'] ? w['last_update'] : 5.6)*fontSize, true, 'center', this.getColor('readOnly'), null, false),
      //this.addDateCell('create_time', 'last_update', true, 8.2*this.state.fontSize, supportRight, 'center', this.getColor('cellAdmin'), this.getColor('textAdmin'), false),
      this.addTextCell('user', 'user', 0, (w && w['user'] ? w['user'] : 6)*fontSize, supportRight, "", null, this.getColor('cellAdmin'), this.getColor('textAdmin'), false),
      { accessor: 'forage_start_back', show: false,},
      { accessor: 'forage_start_forward', show: false,},
      { accessor: 'forage_end_back', show: false,},
      { accessor: 'forage_delay_auger', show: false,},
      { accessor: 'forage_weight', show: false,},
      { accessor: 'forage_fast_on', show: false,},
      { accessor: 'forage_fast_off', show: false,},
      { accessor: 'forage_fast_speed', show: false,},
      { accessor: 'forage_slow_on', show: false,},
      { accessor: 'forage_slow_off', show: false,},
      { accessor: 'forage_slow_speed', show: false,},
      { accessor: 'edit',
        Header: t('table_edit'),
        //width: 10.4*em,
        filterable: false,
        resizable: false,
        sortable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          let del = false, edit = false, update = false, archiv = false
          if (row.status < 2) del = true
          if (row.id > 0 && row.status <= 4) edit = true
          if ((row.status === 1 || row.status === 2) &&  this.state.selected !== row.id && row.check <= 0) update = true
          if (row.status === 4 && row.used === 0) archiv = true
          if (supportRight) edit = true
          if (row.used && row.status >= 3) edit = false
          const getDetails = value => {
            this.setState({
              selectedRow: this.state.data[row._index],
              advancedIndex: row._index,
              showHideEdit: true,
              showPosition: [value.clientX, value.clientY],
            })
          }
          return (
            <div>
              {row.fillingkind_id === 2 || row.fillingkind_id === 3 ? <img src={imgDetail} alt={t("->")} title={t("route_detail")} onClick={getDetails}/> : null}
              {this.edit(row, false, del && userRight, edit && userRight, update && userRight, archiv && userRight)}
              {row.status < 4 && this.state.robotOnline === 0 ? <img src={imgWarnOnline} alt="!" title={t("robot_offline")} /> : null}
            </div>
          )
        },
      }
    ]
    return columns
  }

  render() {
    const userRight = UserProfile.checkRight("custuser")
    const closeEditCols = value => {this.setState({ manageFields: false}); this.componentDidMount()}
    const changeColumnsWidth = (width, name) => {
      const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*this.state.fontSize
      GetJsonFormPHP(this.state.setTableUrl + "?table=robotfeed&col=" + name + "&width=" + width + "&em=" + em).then((result) => {
      })
    }
    const columns = this.createColumns()
    const supportRight = UserProfile.checkRight("support")
    const { showHideEdit } = this.state;
    let buttonHistoric = t('show_history')
    if (this.state.historic) buttonHistoric = t('hide_history')
    //<button onClick={() => {this.checkUsage()}}>{t("feed_check_usages")}</button>
    //<button onClick={() => {this.insertRow()}}>{t("addline")}</button>
    return (
      <div className={supportRight ? "table_100": "table_100"}>
        <div className="table_buttons">
          <button onClick={() => {this.showHistoric()}}>{buttonHistoric}</button>
          <button onClick={() => {this.setState({ manageFields: true, })}}>{t("show_advanced")}</button>
          <div className="right_side"><img id="info" src={require("./img/info.png")} alt="Info" onClick={() => { this.setState({ showHelp: true, showHelpId: "help_robotfeed_table",}) } } onMouseOver={(e) => {e.currentTarget.src=require("./img/info_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src=require("./img/info.png")}} /></div>
        </div>
        <div style={{fontSize: this.state.fontSize+"em"}}>
        <ReactTable
          data={this.state.data}
          columns={columns}
          minRows = {0}
          previousText= {'<'}
          nextText= {'>'}
          showPageJump= {true}
          defaultPageSize = {25}
          pageSizeOptions = {[25, 50, 100]}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              onDoubleClick: (e, t) => { if (rowInfo.index !== this.state.selected && rowInfo.row.status < 5 && !rowInfo.row.used && userRight) { this.setState({ selected: rowInfo.index, }) } },
              style: {
                background: rowInfo && rowInfo.index === this.state.selected ? '#AFC9DF' : this.getStatusBackColor(rowInfo.row.status),
                //color: rowInfo && rowInfo.index === this.state.selected ? 'white' : rowInfo.row.used === 1 ? 'darkgrey' : this.state.statusColor[rowInfo.row.status],
                color: rowInfo && rowInfo.index === this.state.selected ? this.getColor('selected') : rowInfo.row.id < 0 ? 'red' : this.getStatusColor(rowInfo.row.status),
                fontSize: rowInfo.row.status > 4 ? "0.9em" : null
              }
            }
          }}
          getTheadTrProps={(state, column, instance) => {
            return {
              onClick:  (e) => { if (!UserProfile.isSafari()) changeColumnsWidth(e.target.offsetParent.style.width, e.target.offsetParent.innerText) },
            }
          }}
          getProps = {(state, column, instance) => {
            return {
              onChange:  (e) => { if (e.target.type !== "number" && e.target.type !== "text" && e.target.name === "") changeColumnsWidth(e.target.value, "_pageSize") },
            }
          }}
        />
        </div>
        {showHideEdit && <Edit row = {this.state.selectedRow} index = {this.state.advancedIndex} emptydrives = {this.state.emptydrives} position = {this.state.showPosition} closeDiv={this.closeDiv} reload={this.reload} />}
        {this.state.showHelp && <Info id={this.state.showHelpId} closeDiv={() => { this.setState({ showHelp: false, }) }} reset={() => { this.setState({ showHelpId: null, }) }}/>}
        {this.state.manageFields && <ManageCols showfields={this.state.showfields} closeDiv={closeEditCols} page={"robotfeed"}/>}
        <AlertDialog  openDialog = {this.state.confirmVisible} title = {this.state.title} message = {this.state.message} choise = {this.state.choise} callback = {this.handleDelete}/>
      </div>
    )
  }
}
export default RobotFeed;
