import TableFunctions from './api/TableFunctions.js';
import ReactTable from "react-table-6";
import Draggable from "react-draggable";
import AlertDialog from "../user/Dialog.js";
import GetJsonFormPHP, {PostJsonFormPHP} from "./api/GetJsonFormPHP.js";
import Info from "./api/Info.js";
import UserProfile from '../user/UserProfile.js';
import Translation from '../user/Translation.js';
import getStatusVars, {getStatusColors, setTableFontSize} from './api/imports.js';

import './styles/react-table.css';
import './styles/tablestyles.css';
import imgDetail from './img/editdetail.png';
import imgTrue from './img/true.gif';
import imgAttention from './img/attention.png';
import imgWarning from './img/warning.png';
import imgWarnOnline from './img/careful.png'

function t(field) { return Translation.get(field) }

class Edit extends TableFunctions {
  constructor() {
    super();
    this.state = {
      geturl: "/robots/get_drives.php?type=2",
      //saveurl: "/robots/set_drives.php?robot_id=" + parseInt(window.sessionStorage.getItem("robot_id")),
      saveurl: "/robots/set_drives.php?robot_id=" + parseInt(window.sessionStorage.getItem("robot_id")),
      saveurl_vals: "/robots/set_route.php?set_area=1",
      id: null,
      index: null,
      row: null,
      data: null,
      selected: null,
      empty: null,
      edit: false,
      confirmVisible: false, title: null, message: null, choise: true,
      maxDrives: 16, backwardEnabled: false,
      position: [],
      fontSize: setTableFontSize(),
    }
    //this.handleSubmit= this.handleSubmit.bind(this)
    this.handleSubmit1 = this.handleSubmit1.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleBackwardEnabled = this.handleBackwardEnabled.bind(this)
    //this.insertRow = this.insertRow.bind(this)
    //this.reload = this.reload.bind(this)
  }

  reload(newId) {
    let id = this.state.id
    if (newId) id = newId
    GetJsonFormPHP(this.state.geturl + "&id=" + id).then((result) => {
      if (result.data === null) result.data = [this.state.empty]
      this.setState({
        data: result.data,
        selected: null,
      })
    })
    this.props.reload(newId)
  }
  componentDidMount() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    let e1 = document.getElementById("edit_window")
    e1.style.left = (window.scrollX+10*em)+'px'
    e1.style.top =(window.scrollY+6*em)+'px';
    /*const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    let e1 = document.getElementById("edit_box")
    e1.style.left = (this.state.position[0]-28*em)+'px';
    e1.style.top =(this.state.position[1]-2*em)+'px';*/
  }
  handleInputChange1(value, inputType = null, decimals = null, min = null, max = null) {
    const target = value.target.name
    if (this.checkInputChars(value, inputType)) {
      let row = this.state.row
      let newVal = value.target.value
      if (min !== null && (inputType === 1 || inputType === 2) && value.target.value < min) newVal = min
      if (max !== null && (inputType === 1 || inputType === 2) && value.target.value > max) newVal = max
      if (inputType === 2 && decimals !== null && newVal !== "-" && newVal !== "+" && newVal !== ".") {
        newVal = newVal*10**decimals
        if (newVal < 0) newVal = Math.round(newVal)
        else newVal = Math.round(newVal)
        //newVal = Math.round(newVal)
        newVal = newVal/10**decimals
        if (parseFloat(value.target.value) === parseFloat(newVal)) newVal = value.target.value
      }
      for (let key in row) { if (key === target) row[key] = newVal }
      /*if (!this.state.row.backward_enabled) {
        const target_back = target.replace("forward", 'backward')
        for (let key in row) { if (key === target_back) row[key] = newVal }
      }*/
      this.setState({
        row: row
      })
    }
  }
  handleBackwardEnabled(value) {
    //value.preventDefault();
    //console.log(value, value.target.value)
    let row = this.state.row
    //row.backward_enabled = !row.backward_enabled
    row.backward_start1 = row.forward_start1
    row.backward_start2 = row.forward_start2
    row.backward_start3 = row.forward_start3
    //row.backward_start4 = row.forward_start4
    row.backward_end1 = row.forward_end1
    row.backward_end2 = row.forward_end2
    row.backward_end3 = row.forward_end3
    //row.backward_end4 = row.forward_end4
    this.setState({ row: row, })
  }
  handleSubmit1() {
    let row=this.state.row
    for (let key in row) {
      if (key.substring(0,1) === "_") delete row[key]
    }
    row.action = "UPDATE"
    PostJsonFormPHP(this.state.saveurl_vals, row).then((result) => {
      this.setState({
        id: 0,
        row: null,
      })
      this.reload(result.new_id)
    })
    //this.handleClose()
  }
  handleClose() {
    this.props.closeDiv();
  }
  static getDerivedStateFromProps(props, state) {
    if (state.id !== props.row.id) {
      let empty = props.emptydrives
      empty.animalgroup_id = props.row.id
      empty.type = 2
      let drives = props.row.drives
      if (drives === null) drives = [empty]
      //console.log(props.row)
      return {
        index: props.index,
        id: props.row.id,
        row: props.row,
        data: drives,
        empty:  empty,
        maxDrives: props.maxDrives,
        position: props.position,
        //backwardEnabled: props.row.backward_enabled,
      }
    }
    return null
  }
  createColumns() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*this.state.fontSize
    const supportRight = UserProfile.checkRight("support")
    const userRight = UserProfile.checkRight("custuser")
    let addRight = userRight
    if (this.state.data.length >= this.state.maxDrives) addRight = false
    return [
      { accessor: 'id', show: false, },
      { accessor: 'customer_id', show: false,},
      { accessor: 'starttime',
        Header: t('drive_time'),
        Cell: ({value: initialValue, column: { id }, row,}) => { return this.textCell(initialValue, id , row, 3) },
        getProps: (state, rowInfo) => ({ style: { textAlign: "center" } }),
      },
      { accessor: 'animalgroup_id', show: false,},
      { accessor: 'type', show: false,},
      { accessor: 'last_update',
        Header: t('last_update'),
        show: supportRight,
        getProps: (state, rowInfo) => ({ style: { backgroundColor: '#F9EEEE', color: "#999999" } }),
        width: 8.2*em,
      },{ accessor: 'user',
        Header: <div className="admin">{t('last_user_name')}</div>,
        show: supportRight,
        getProps: (state, rowInfo) => ({ style: { backgroundColor: '#F9EEEE', color: "#999999" } }),
        width: 6*em,
      },{ accessor: 'edit',
        Header: t('table_edit'),
        width: 7.5*em,
        filterable: false,
        resizable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => { return this.edit(row, addRight, userRight, userRight, false) },
      }
    ]
  }

  render() {
    const columns = this.createColumns()
    const supportRight = UserProfile.checkRight("support")
    const userRight = UserProfile.checkRight("custuser")
    const enableBackward = value => { this.handleBackwardEnabled(value) }
    if (this.state.id !== 0) {
      const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
      let e1 = document.getElementById("edit_window")
      if (e1) {
        e1.style.left = (window.scrollX+10*em)+'px'
        e1.style.top =(window.scrollY+6*em)+'px';
        //e1.style.left = (this.state.position[0]-28*em)+'px';
        //e1.style.top =(this.state.position[1]-2*em)+'px';
      }
      const MINVAL = -999
      const MAXVAL = 999
      /*
                <tr>
                  <td align="right" className="nowarp"><input type="text" name="forward_start4" value={this.state.row.forward_start4} onChange={(value) => { this.handleInputChange1(value, 2, 1, MINVAL, MAXVAL)}} className="right_align" /> m</td>
                  <td align="right" className="nowarp"><input type="text" name="forward_end4" value={this.state.row.forward_end4} onChange={(value) => { this.handleInputChange1(value, 2, 1, MINVAL, MAXVAL)}} className="right_align" /> m</td>
                </tr>
                <tr>
                  <td align="right"><input type="text" name="backward_start4" value={this.state.row.backward_start4} onChange={(value) => { this.handleInputChange1(value, 2, 1, MINVAL, MAXVAL)}} className="right_align" disabled={!this.state.row.backward_enabled} /> m</td>
                  <td align="right"><input type="text" name="backward_end4" value={this.state.row.backward_end4} onChange={(value) => { this.handleInputChange1(value, 2, 1, MINVAL, MAXVAL)}} className="right_align" disabled={!this.state.row.backward_enabled} /> m</td>
                </tr>

                  <input id="backward_enabled" type="checkbox" class="checkbox" checked={this.state.row.backward_enabled} onChange={enableBackward}/>{t('route_backward')}
      */
      const editRight = true
      return (
        <Draggable handle=".handle">
        <div className="edittable" id="edit_window">
        <div className="header">
            <div className="header_inline handle">{t('drives_cleaning')}: {this.state.row.name}</div>
            <div className="header_inline close"><img src={require("./img/close.png")} alt={t('close')} className="close" onClick={this.handleClose} onMouseOver={(e) => {e.currentTarget.src = require("./img/close_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("./img/close.png")}} /></div>
          </div>
          <form>
          <table><tbody><tr>
          {userRight && editRight ? <td width="65%" className="top">
            <div><table>
              <thead>
                <tr><th colSpan="2">{t('route_forward')}</th></tr>
                <tr><th>{t('route_begin')}</th><th colSpan="2">{t('route_end')}</th></tr>
              </thead>
              <tbody>
                <tr>
                  <td align="right" className="nowarp"><input type="text" name="forward_start1" value={this.state.row.forward_start1} onChange={(value) => { this.handleInputChange1(value, 2, 1, MINVAL, MAXVAL)}} className="right_align" /> m</td>
                  <td align="right" className="nowarp"><input type="text" name="forward_end1" value={this.state.row.forward_end1} onChange={(value) => { this.handleInputChange1(value, 2, 1, MINVAL, MAXVAL)}} className="right_align" /> m</td>
                </tr>
                <tr>
                  <td align="right" className="nowarp"><input type="text" name="forward_start2" value={this.state.row.forward_start2} onChange={(value) => { this.handleInputChange1(value, 2, 1, MINVAL, MAXVAL)}} className="right_align" /> m</td>
                  <td align="right" className="nowarp"><input type="text" name="forward_end2" value={this.state.row.forward_end2} onChange={(value) => { this.handleInputChange1(value, 2, 1, MINVAL, MAXVAL)}} className="right_align" /> m</td>
                </tr>
                <tr>
                  <td align="right" className="nowarp"><input type="text" name="forward_start3" value={this.state.row.forward_start3} onChange={(value) => { this.handleInputChange1(value, 2, 1, MINVAL, MAXVAL)}} className="right_align" /> m</td>
                  <td align="right" className="nowarp"><input type="text" name="forward_end3" value={this.state.row.forward_end3} onChange={(value) => { this.handleInputChange1(value, 2, 1, MINVAL, MAXVAL)}} className="right_align" /> m</td>
                </tr>
              </tbody>
              <thead>
                <tr><th colSpan="2">
                  {t("route_backward")} &nbsp;
                  <input type="button" value={t("route_backward_copy")} onClick={enableBackward} className="button small"/>
                </th></tr>
                <tr><th>{t('route_begin')}</th><th colSpan="2">{t('route_end')}</th></tr>
              </thead>
              <tbody>
                <tr>
                  <td align="right"><input type="text" name="backward_start1" value={this.state.row.backward_start1} onChange={(value) => { this.handleInputChange1(value, 2, 1, MINVAL, MAXVAL)}} className="right_align" /> m</td>
                  <td align="right"><input type="text" name="backward_end1" value={this.state.row.backward_end1} onChange={(value) => { this.handleInputChange1(value, 2, 1, MINVAL, MAXVAL)}} className="right_align" /> m</td>
                </tr>
                <tr>
                  <td align="right"><input type="text" name="backward_start2" value={this.state.row.backward_start2} onChange={(value) => { this.handleInputChange1(value, 2, 1, MINVAL, MAXVAL)}} className="right_align" /> m</td>
                  <td align="right"><input type="text" name="backward_end2" value={this.state.row.backward_end2} onChange={(value) => { this.handleInputChange1(value, 2, 1, MINVAL, MAXVAL)}} className="right_align" /> m</td>
                </tr>
                <tr>
                  <td align="right"><input type="text" name="backward_start3" value={this.state.row.backward_start3} onChange={(value) => { this.handleInputChange1(value, 2, 1, MINVAL, MAXVAL)}} className="right_align" /> m</td>
                  <td align="right"><input type="text" name="backward_end3" value={this.state.row.backward_end3} onChange={(value) => { this.handleInputChange1(value, 2, 1, MINVAL, MAXVAL)}} className="right_align" /> m</td>
                </tr>
                <tr><td colSpan="3" align="center"><input type="button" value={t("save")} onClick={this.handleSubmit1} className="button"/></td></tr>
              </tbody>
            </table><br /></div>
          </td> : null }
          <td valign="top" width="35%">
            <table>
            <thead>
                <tr><th colSpan="2">{t('route_drivetimes')}</th></tr>
              </thead>
            </table>
            <div style={{"font-size": this.state.fontSize+"em"}}>
            <div className={supportRight ? "table_80": "table_60"}>
              <ReactTable
                data={this.state.data}
                columns={columns}
                minRows = {0}
                previousText= {'<'}
                nextText= {'>'}
                useFlexLayout = {true}
                showPaginationBottom={false}
                getTrProps={(state, rowInfo, column, instance) => {
                  return {
                    onDoubleClick: (e, t) => { if (rowInfo.index !== this.state.selected && userRight) { this.setState({ selected: rowInfo.index, })}},
                      style: {
                        background: rowInfo && rowInfo.index === this.state.selected ? '#AFC9DF' : 'white',
                        color: rowInfo && rowInfo.index === this.state.selected ? 'white' : 'black'
                      }
                  }
                }}
              />
            </div></div>
            </td></tr></tbody></table>
          </form>
          <AlertDialog  openDialog = {this.state.confirmVisible} title = {this.state.title} message = {this.state.message} choise = {this.state.choise} callback = {this.handleDelete}/>
        </div>
        </Draggable>
      )
    }
    else return null
  }
}

class Routes extends TableFunctions {
  constructor() {
    super();
    this.state={
      geturl: "/robots/get_route.php",
      saveurl: "/robots/set_route.php",
      updateurl: "/robots/send_route.php",
      setTableUrl: "/users/change_tablesize.php",
      data: [], empty: null, selected: null, // Table data
      customers: null, animalgroups: null, side: [t('side_center'), t('side_left'), t('side_right')], path: [0,1], choise1: [t('no'), t('yes')], // Select input options
      confirmVisible: false, title: null, message: null, choise: true,  // OK popup and Error Messages
      status: getStatusVars(), statusColor: getStatusColors(), fontSize: setTableFontSize(),
      showHelp: false, showHelpId: null, // Info popup
      showHideEdit: false, showPosition: [],
      fieldWidths: null,
      advancedIndex: null,
      emptydrives: null,
      maxDrives: 16,
      robotOnline: 0,
    }
    this.closeDiv = this.closeDiv.bind(this)
    this.reload = this.reload.bind(this)
  }
  reload(newId) {
    this.componentDidMount(this.state.historic, newId)
  }
  componentDidMount(historic = false, newId = null) {
    GetJsonFormPHP(this.state.geturl + "?historic=" + historic).then((result) => {
      this.setState({
        empty: result.empty,
        emptydrives: result.empty_drives,
      })
      if (result.data === null) {
        result.data = [this.state.empty]
        result.data_drives = [this.state.emptydrives]
      }
      if (newId != null) {
        result.data.map((t,k) => { if (parseInt(newId) === parseInt(t.id)) this.setState({ selectedRow: t, advancedIndex: k,}); return null})
      }
      this.setState({
        data: result.data,
        customers: result.customers,
        animalgroups: result.animalgroups,
        maxDrives: result.max_drives,
        path: result.paths,
        robotOnline: result.robot_online,
        fieldWidths: result.fieldwidth,
      })
    })
  }
  closeDiv() {
    this.setState({
      selected: null,
      showHideEdit: false,
    })
    this.componentDidMount(this.state.historic)
  }
  createColumns() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*this.state.fontSize
    const su = UserProfile.checkRight("superuser")
    const supportRight = UserProfile.checkSupport()
    const userRight = UserProfile.checkRight("custuser")
    const feeder = UserProfile.getFeeder()
    const w = this.state.fieldWidths
    console.log (w)
    const fontSize = this.state.fontSize
    return [
      { accessor: 'id', show: false, },
      { accessor: 'customer_id', show: false, },
      { accessor: 'org_id', show: false,},
      { accessor: 'sps_nr',
        Header: t('route_nr'),
        getProps: (state, rowInfo) => ({ style: { textAlign: "right" } }),
        Cell: ({value: initialValue, column: { id }, row,}) => { if (supportRight) return this.textCell(initialValue, id, row, 1); else return parseInt(initialValue) < 0 ? null : initialValue },
        width: (w && w['sps_nr'] ? w['sps_nr'] : 3.5)*em,
      },
      this.addTextCell('route_name', 'name', 0, (w && w['name'] ? w['name'] : 0)*fontSize),
      { accessor: 'forward_start', show: false, },
      { accessor: 'forward_end', show: false, },
      { accessor: 'backward_start', show: false, },
      { accessor: 'backward_end', show: false, },
      { accessor: 'backward_enabled', show: false, },
      this.addSelectCell("box_path", "start_path", this.state.path, (w && w['start_path'] ? w['start_path'] : 6.5)*fontSize, true, null, null, false, true),
      this.addSelectCell("box_side", "start_side", this.state.side, (w && w['start_side'] ? w['start_side'] : 5)*fontSize, true, 'center', null, false, true),
      //his.addTextCell('box_offset', 'start_offset', 2, 5*fontSize, true, "m", null, this.getColor('notused'), null, true),
      this.addNumberCell('route_start_offset', 'start_offset', 2, -3, 3, (w && w['start_offset'] ? w['start_offset'] : 6)*fontSize, feeder === "ARANOM" ? true : false, "m", null, null, null, true, false, 2),
      this.addSelectCell("route_north_magnets", "north_magnets", this.state.choise1, (w && w['north_magnets'] ? w['north_magnets'] : 4)*fontSize, feeder === "ARANOM" ? true : false, "center", null, false, true, false, false, null, true),
      { accessor: 'drives',
        Header: t('animals_drives_check'),
        Cell: ({value: initialValue, column: { id }, row,}) => {
          let img = imgTrue
          let title = ""
          if (row.drives === null) {
            img = imgWarning
            title = title + t("animals_drives_missing")
          }
          if (parseInt(row.start_path) === 0) {
            img = imgAttention
            if (title !== "") title = title + "\n"
            title = title + t("box_path_missing")
          }
          if (title === "") title = t("ok")
          return  (<div width="100%" align="center"><img src={img} alt={title} title={title} /></div>)
        },
        getProps: (state, rowInfo) => ({ style: {  backgroundColor: rowInfo.row.status === 5 || rowInfo.row.status === 6 ? 'lightgrey' : null } }),
        maxWidth: (w && w['drives'] ? w['drives'] : 5)*em,
      },
      this.addSelectCell("status", "status", this.state.status, (w && w['status'] ? w['status'] : 5.5)*fontSize, su && !this.state.advanced, 'center', '#F9EEEE'),
      this.addDateCell('create_time', 'last_update', true, (w && w['last_update'] ? w['last_update'] : 8.5)*fontSize, supportRight, null, 'readonly', null, false),
      this.addTextCell('last_user_name', 'user', 0, (w && w['user'] ? w['user'] : 6)*fontSize, supportRight, "", null, '#F9EEEE', "#999999", false),
      { accessor: 'edit',
        Header: t('table_edit'),
        width: (supportRight ? 10.4 : 8)*em,
        filterable: false,
        resizable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          const getDetails = value => {
            this.setState({
              selectedRow: this.state.data[row._index],
              advancedIndex: row._index,
              showHideEdit: true,
              showPosition: [value.clientX, value.clientY],
            })
          }
          let del = false
          let edit = false
          if (row.id > 0 && row.status <= 4) edit = true
          if (row.status < 2) del = true
          if (row.used === 1) edit = false
          return (
            <div>
              <img src={imgDetail} alt={t("->")} title={t("route_detail")} onClick={getDetails}/>
              {this.edit(row, false, del && userRight, edit && userRight, (parseInt(row.status) === 1 || parseInt(row.status) === 2) && this.state.selected !== row.id && userRight)}
              {row.status < 4 && this.state.robotOnline === 0 ? <img src={imgWarnOnline} alt="!" title={t("robot_offline")} /> : null}
            </div>
          )
        },
        getProps: (state, rowInfo) => ({ style: {  backgroundColor: rowInfo.row.status === 5 || rowInfo.row.status === 6 ? 'lightgrey' : null } }),
      },
    ]
  }
  recalc_fields(v1, event, index) {
    let data = this.state.data
    let row = data[index]
    if (event.target.name === "start_side" && parseInt(event.target.value) === 0) {
      for (let key in row) { if (key === "start_offset") { row[key] = "0.0" }}
      this.setState({ data: data })

    }
  }

  render() {
    const columns = this.createColumns()
    const supportRight = UserProfile.checkSupport()
    const userRight = UserProfile.checkRight("custuser")
    const { showHideEdit } = this.state;
    const changeColumnsWidth = (width, name) => {
      const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*this.state.fontSize
      GetJsonFormPHP(this.state.setTableUrl + "?table=cleardrives&col=" + name + "&width=" + width + "&em=" + em).then((result) => {
      })
    }
    let buttonHistoric = t('show_history')
    if (this.state.historic) buttonHistoric = t('hide_history')
    return (
      <div className={supportRight ? "table_80": "table_80"}>
        <div className="table_buttons">
          {supportRight ? <button className="only_support" onClick={() => {this.insertRow()}}>{t("addline")}</button> : null }
          <button className={this.state.historic ? "button_active" : null} onClick={() => {this.setState({ historic: !this.state.historic, }); this.componentDidMount(!this.state.historic)}}>{buttonHistoric}</button>
          <div className="right_side"><img id="info" src={require("./img/info.png")} alt="Info" onClick={() => { this.setState({ showHelp: true, showHelpId: "help_cleardrive_table",}) } } onMouseOver={(e) => {e.currentTarget.src=require("./img/info_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src=require("./img/info.png")}} /></div>
        </div>
        <div style={{"font-size": this.state.fontSize+"em"}}>
        <ReactTable
          data={this.state.data}
          columns={columns}
          minRows = {0}
          previousText= {'<'}
          nextText= {'>'}
          showPageJump= {true}
          defaultPageSize = {25}
          pageSizeOptions = {[25, 50, 100]}
          useFlexLayout = {true}
          expanded={this.state.expanded}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
                onDoubleClick: (e, t) => { if (rowInfo.index !== this.state.selected && rowInfo.row.id > 0 && rowInfo.row.status <= 4 && rowInfo.row.used !== 1 && userRight) { this.setState({ selected: rowInfo.index, }) } },
                style: {
                  background: rowInfo && rowInfo.index === this.state.selected ? '#AFC9DF' : this.getStatusBackColor(rowInfo.row.status),
                  color: rowInfo && rowInfo.index === this.state.selected ? this.getColor('selected') : rowInfo.row.id < 0 ? 'red' : this.getStatusColor(rowInfo.row.status),
                  fontSize: rowInfo.row.status > 4 ? "0.9em" : null
                }
            }
          }}
          getTheadTrProps={(state, column, instance) => {
            return {
              onClick:  (e) => {if (!UserProfile.isSafari()) changeColumnsWidth(e.target.offsetParent.style.width, e.target.offsetParent.innerText)},
            }
          }}
        />
        </div>
        {showHideEdit && <Edit row = {this.state.selectedRow} index = {this.state.advancedIndex} emptydrives = {this.state.emptydrives} maxDrives = {this.state.maxDrives} position = {this.state.showPosition} closeDiv={this.closeDiv} reload={this.reload} />}
        {this.state.showHelp && <Info id={this.state.showHelpId} closeDiv={() => { this.setState({ showHelp: false, }) }} reset={() => { this.setState({ showHelpId: null, }) }}/>}
        <AlertDialog  openDialog = {this.state.confirmVisible} title = {this.state.title} message = {this.state.message} choise = {this.state.choise} callback = {this.handleDelete}/>
      </div>
    )
  }
}
export default Routes;
